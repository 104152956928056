<template>
	<v-row no-gutter dense class="account R-Layout d-flex flex-column">
		<div class="d-flex justify-start pb-10" style="margin-top:-40px;">
			<div v-on:click="changeTab('password')">
				<app-back-btn />
			</div>
		</div>

		<v-row>
			<v-col cols="12" sm="8">
				<v-form v-model="valid" ref="form" lazy-validation>
					<v-card tag="div" elevation="0" outlined color="#F7F9FC">
						<p class="my-heading ma-0" style="padding-bottom:48px">Review account information</p>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>First Name</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									v-model="account_data.first_name"
									placeholder="First name"
									:rules="firstnameRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>Last Name</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									v-model="account_data.last_name"
									placeholder="Last name"
									:rules="lastnameRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>Phone</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									v-model="account_data.phone"
									v-mask="mask"
									placeholder="Phone"
									:rules="phoneRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row
							dense
							v-if="$store.state.auth.error != ''"
							class="align-center justify-center p-0 custom-error"
						>
							<v-col cols="12">{{ this.$store.state.auth.error }}</v-col>
						</v-row>
					</v-card>
				</v-form>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="d-flex align-self-sm-end justify-sm-end pb-0" cols>
				<v-btn class="my-outline-btn mr-5" depressed outlined @click="skipSettings">Skip settings</v-btn>

				<v-btn
					class="my-p-btn my_elevation"
					id="my_elevation"
					depressed
					@click="account"
					:disabled="!valid"
				>Next</v-btn>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { setUser, userProfile } from "../../services/registerservice";

export default {
	data() {
		return {
			account_data: {
				user_id: "",
				first_name: "",
				last_name: "",
				phone: ""
			},
			mask: "(###)-###-####",
			show: true,
			valid: true,

			firstnameRules: [
				v => !!v || "First name is required",
				v => /^[A-Za-z]+$/.test(v) || "Input alphabet character only"
			],
			lastnameRules: [
				v => !!v || "First name is required",
				v => /^[A-Za-z]+$/.test(v) || "Input alphabet character only"
			],
			phoneRules: [
				v => !!v || "Phone number is required",
				v =>
					(v && v.length >= 14 && v.length <= 14) ||
					"Phone number must be 10 digit"
			]
		};
	},

	beforeRouteLeave(to, from, next) {
		this.storeVuex();
		next();
	},

	async created() {
		if (this.$store.state.register.register_user.user_id === "") {
			await setUser();
			this.myAccount();
		} else {
			this.myAccount();
		}
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		}
	},

	methods: {
		myAccount() {
			let data = JSON.parse(
				JSON.stringify(this.$store.state.register.register_user)
			);

			this.account_data.user_id = data.user_id;
			this.account_data.first_name = data.first_name;
			this.account_data.last_name = data.last_name;
			this.account_data.phone = data.phone;
		},

		storeVuex() {
			this.$store.state.register.register_user.first_name = this.account_data.first_name;
			this.$store.state.register.register_user.last_name = this.account_data.last_name;
			this.$store.state.register.register_user.phone = this.account_data.phone;
		},

		changeTab(tab_name) {
			this.errorMessages = "";
			this.$store.commit("errorMessage", "");
			this.$store.commit("registerTab", tab_name);
		},

		async account() {
			if (this.$refs.form.validate()) {
				this.$store.commit("errorMessage", "");

				let account_info = {
					fullname:
						this.account_data.first_name +
						" " +
						this.account_data.last_name,
					phone: this.account_data.phone
				};

				let data = await userProfile(
					this.account_data.user_id,
					account_info
				);
				this.storeVuex();
				if (data.data) {
					this.$store.commit("registerUser", data.data);
					this.$store.commit("accountcomplete");
					this.changeTab("product");
				}
			}
		},
		skipSettings() {
			this.changeTab("product");
		}
	}
};
</script>

<style lang="scss">
</style>